import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IndexActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, SearchCombo } from 'components'
import Dependent from 'containers/shared/Dependent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IndexIcon from '@material-ui/icons/ScatterPlot'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import { Authorization } from 'utils'
import withStyles from 'styles'

export class List extends Component{

  constructor(props){
    super(props)
    IndexActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.query !== this.props.query) {
      this.loadIndices()
    }
  }

  dependsOn(){
    return this.loadIndices()
  }

  loadIndices = () => {
    return this.actions.index({
      page: this.props.page,
      filter: this.props.filter,
      fields: {indices: 'name,code'}
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showIndex = id => () => {
    this.props.history.push(`/indices/${id}`)
  }

  editIndex = id => event => {
    this.props.history.push(`/indices/${id}/edit`)
    event.stopPropagation()
  }

  deleteIndex = id => event => {
    this.actions.destroy({id})
                .then(this.loadIndices)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get indices(){
    return this.props.indices
  }

  get filter() {
    const { filter } = this.props
    return filter
  }

  handleFilterChange = filter => {
    this.props.onFilterChange(filter)
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadIndices()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderIndexListItem = ({id, name, code}) =>
    <ListItem button onClick={this.showIndex(id)} key={id}>
      <ListItemIcon>
        <IndexIcon />
      </ListItemIcon>
      <ListItemText primary={<>
        <span className={this.props.classes.indexCode}>{code || '-' }</span>
        <span className={this.props.classes.indexName}>{name}</span>
      </>}/>
      <ListItemSecondaryAction>
        {
          Authorization.admin &&
          <>
            <IconButton onClick={this.editIndex(id)}><EditIcon/></IconButton>
            <IconButton onClick={this.deleteIndex(id)}><DeleteIcon/></IconButton>
          </>
        }
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderSearchCombo = () =>
    <SearchCombo onFilterChange={this.handleFilterChange} filter={this.filter}/>

  render = () =>
     <PageContainer>
      <ActionHeader title="Indices">
        <Button color="primary" variant="contained" onClick={() => this.props.history.push('/exchange_rates')} style={{ marginRight: 5 }}>Exchange Rates</Button>
        <Button color="primary" variant="contained" onClick={() => this.props.history.push('/reference_rates')} style={{ marginRight: 5 }}>Reference Rates</Button>
        {
          Authorization.admin &&
          <Button color="primary" variant="contained" onClick={() => this.props.history.push('/indices/new')}>Add</Button>
        }
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}  startAdornment={this.renderSearchCombo()}/>
      <MuiList dense>
        {this.indices.map(this.renderIndexListItem)}
      </MuiList>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    </PageContainer>
}

const styles = theme => ({
  indexName: {
    display: 'inline-block'
  },
  indexCode: {
    margin: "0 5px",
    display: "inline-block",
    fontWeight: "bold",
    background: "#dbdbdb",
    minWidth: "80px",
    padding: '0 10px 0 10px',
    textAlign: "center",
    borderRadius: "14px",
    float: "left",
    lineHeight: "1.6",
    paddingTop: "1px",
  }
})

export default compose(
  Dependent({loader: true}),
  connectQueryString('indices'),
  connect(({indices}) => indices),
  withStyles(styles),
)(List)